export default defineEventHandler((event) => {
    // Eğer URL "/api" ile başlıyorsa
    if (event.node.req.url.startsWith("/api")) {
      event.node.res.setHeader(
        "Cache-Control",
        "no-store, no-cache, must-revalidate, proxy-revalidate"
      );
      event.node.res.setHeader("Pragma", "no-cache");
      event.node.res.setHeader("Expires", "0");
    }
  });
  